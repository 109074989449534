const parseTime = (time: string): string => {
  // time in format HH:MM:SS
  const parts = time.split(":");
  if (parts.length > 2) {
    // then the time has an hour component
    if (parseInt(parts[0], 10) > 0) {
      return `${parts[0]}h ${parts[1]}m`;
    }
    return `${parts[1]}m`;
  }
  // there is no hour component
  if (parseInt(parts[0], 10) > 0) {
    return `${parts[0]}m ${parts[1]}s`;
  }
  return `${parts[1]}s`;
};

export { parseTime };
