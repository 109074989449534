import { baseAPIURL, HOST } from "./config";

const api = {
  auth: {
    refreshToken: `${HOST}/rest-auth/token/refresh/`,
    tokenExpiration: `${HOST}/rest-auth/token/expiration/`,
    login: `${HOST}/rest-auth/login/`,
    signup: `${HOST}/rest-auth/registration/`,
    logout: `${HOST}/rest-auth/logout/`,
    social: {
      google: {
        login: `${HOST}/rest-auth/google/`,
        connect: `${HOST}/rest-auth/google/connect/`
      },
      github: {
        login: `${HOST}/rest-auth/github/`,
        connect: `${HOST}/rest-auth/github/connect/`
      },
      list: `${HOST}/socialaccounts/`,
      disconnect: (pk: number): string => `${HOST}/socialaccounts/${pk}/disconnect/`
    },
    resetPassword: `${HOST}/rest-auth/password/reset/`,
    resetPasswordConfirm: `${HOST}/rest-auth/password/reset/confirm/`,
    confirmEmail: `${HOST}/rest-auth/registration/verify-email/`,
    changePassword: `${HOST}/rest-auth/password/change/`,
    user: `${HOST}/rest-auth/user/`
  },
  account: {
    hasPassword: `${baseAPIURL}/has-password/`,
    setPassword: `${baseAPIURL}/set-password/`,
    close: `${baseAPIURL}/close-account/`,
    confirmBootcamp: `${baseAPIURL}/complete-bootcamp-signup/`,
    exists: `${baseAPIURL}/exists/`
  },
  analytics: {
    dashboard: `${baseAPIURL}/dashboard/progress/`,
    activity: `${baseAPIURL}/dashboard/activity/`,
    activities: {
      create: `${baseAPIURL}/record-activity/`
    },
    unsubscribe: `${baseAPIURL}/unsubscribe/`,
    recommendation: `${baseAPIURL}/recommendation/`
  },
  billing: {
    previewPackageChange: (planID: string): string =>
      `${baseAPIURL}/payments/plan/${planID}/preview/`,
    addPaymentMethod: `${baseAPIURL}/payments/add-payment-method/`,
    payDueInvoice: `${baseAPIURL}/payments/pay-due-invoice/`,
    fetchDueInvoice: `${baseAPIURL}/payments/fetch-due-invoice/`,
    paypalCallback: `${baseAPIURL}/paypal/callback/`,
    coupon: `${baseAPIURL}/coupon/`,
    pricing: `${baseAPIURL}/pricing/`,
    customerPortalSession: `${baseAPIURL}/customer-portal-session/`,
    createJDLearnCheckoutSession: `${baseAPIURL}/create-jdlearn-checkout-session/`,
    paypalCreateOrder: `${baseAPIURL}/paypal/create-transaction/`
  },
  categories: {
    list: `${baseAPIURL}/categories/`
  },
  courses: {
    retrieve: (slug: string): string => `${baseAPIURL}/courses/${slug}/`,
    nextLecture: (slug: string): string => `${baseAPIURL}/courses/${slug}/next-lecture/`,
    progress: (slug: string): string => `${baseAPIURL}/progress/${slug}/`
  },
  general: {
    contact: `${baseAPIURL}/contact/`
  },
  lectures: {
    retrieve: (courseSlug: string, lectureSlug: string): string =>
      `${baseAPIURL}/courses/${courseSlug}/${lectureSlug}/`
  },
  memberships: {
    summary: `${baseAPIURL}/merchant-summary/`,
    retrieve: `${baseAPIURL}/membership/`,
    cards: {
      retrieve: `${baseAPIURL}/current-card/`,
      create: `${baseAPIURL}/add-card/`,
      delete: `${baseAPIURL}/remove-card/`,
      setDefault: `${baseAPIURL}/set-default-card/`
    },
    transactions: {
      list: `${baseAPIURL}/transactions/`
    },
    cancel: `${baseAPIURL}/membership/cancel/`,
    stopCancellation: `${baseAPIURL}/membership/stop-cancellation/`
  },
  profiles: {
    badge: `${baseAPIURL}/profile-badge/`,
    experience: `${baseAPIURL}/user-experience/`,
    overview: `${baseAPIURL}/user-details/`,
    dashboard: `${baseAPIURL}/dashboard/`,
    email: {
      retrieve: `${baseAPIURL}/email/`,
      update: `${baseAPIURL}/change-email/`
    },
    password: {
      update: `${baseAPIURL}/change-password/`
    },
    notifications: {
      retrieve: `${baseAPIURL}/notifications/`,
      update: `${baseAPIURL}/change-notifications/`
    },
    settings: {
      retrieve: `${baseAPIURL}/settings/`,
      update: `${baseAPIURL}/change-settings/`
    }
  },
  roadmaps: {
    retrieve: (slug: string): string => `${baseAPIURL}/roadmaps/${slug}/`
  },
  sections: {
    list: (courseSlug: string): string => `${baseAPIURL}/sections/${courseSlug}/`
  },
  payment: {
    createPaymentMethod: `${baseAPIURL}/payments/create-payment-method/`,
    getPlanDetails: (planID: string): string => `${baseAPIURL}/payments/plan/${planID}`,
    getPublicStripeKey: `${baseAPIURL}/payments/public-key/`
  }
};

export default api;
