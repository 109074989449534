const ENV = process.env.NODE_ENV || "development";

const GOOGLE_CLIENT_ID: string = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const SENTRY_DNS: string = process.env.REACT_APP_SENTRY_DNS;
const PROFITWELL_AUTH_TOKEN: string = process.env.REACT_APP_PROFITWELL;
const PAYPAL_CLIENT_ID: string = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const STRIPE_PUBLIC_KEY: string = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const DJANGO_BASIC_LIFETIME_ID: string = process.env.REACT_APP_DJANGO_BASIC_LIFETIME_ID;
const DJANGO_PRO_LIFETIME_ID: string = process.env.REACT_APP_DJANGO_PRO_LIFETIME_ID;
const DJANGO_BASIC_MONTHLY_ID: string = process.env.REACT_APP_DJANGO_BASIC_MONTHLY;
const DJANGO_PRO_MONTHLY_ID: string = process.env.REACT_APP_DJANGO_PRO_MONTHLY;

const environment = process.env.REACT_APP_ENV;

const DEVELOPMENT = "development";

const DEBUG = ENV === DEVELOPMENT;

const HOST = DEBUG ? "http://127.0.0.1:8000" : "https://django.caprover.justdjango.com";

const googleClientID = GOOGLE_CLIENT_ID;
const baseAPIURL = `${HOST}/api`;

export {
  ENV,
  googleClientID,
  baseAPIURL,
  HOST,
  DEBUG,
  PROFITWELL_AUTH_TOKEN,
  environment,
  SENTRY_DNS,
  PAYPAL_CLIENT_ID,
  STRIPE_PUBLIC_KEY,
  DJANGO_BASIC_LIFETIME_ID,
  DJANGO_PRO_LIFETIME_ID,
  DJANGO_BASIC_MONTHLY_ID,
  DJANGO_PRO_MONTHLY_ID
};
