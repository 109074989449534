import React, { useContext, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import axios, { AxiosInstance } from "axios";
import { Button, VisaIcon, MastercardIcon, AmericanExpressIcon, PayPalIcon } from "src/components";
import { AuthContext } from "src/contexts";
import { STRIPE_PUBLIC_KEY } from "src/config";
import { useOldFetch } from "src/helpers";
import { PaypalPurchaseButton } from "src/containers/Forms/PaypalButton";
import api from "src/api";
import Logo from "src/assets/images/logo.svg";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export default function Payment(): JSX.Element {
  const { planID } = useParams<{ planID: string }>();
  const [paymentOption, setPaymentOption] = useState<"card" | "paypal">("card");
  const { data: plan } = useOldFetch(api.payment.getPlanDetails(planID), null);

  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const { authAxios } = useContext(AuthContext);
  const history = useHistory();

  async function handleStripeCheckout() {
    setLoading(true);
    let requester: AxiosInstance = axios;
    if (user) {
      requester = authAxios;
    }
    const sessionId = await requester
      .post(api.billing.createJDLearnCheckoutSession, {
        planId: plan.stripe_id
      })
      .then((res: { data: { sessionId: string } }) => res.data.sessionId);
    const stripe = await stripePromise;
    try {
      stripe!.redirectToCheckout({ sessionId }).then((res) => {
        console.log(res);
      });
    } catch (e) {
      setLoading(false);
      console.log("There was an error");
      console.log(e);
    }
  }

  function handlePaypalCheckout() {
    history.push("/success");
  }

  function planAmountDisplay() {
    return plan ? `${plan.amount}.00` : null;
  }

  return (
    <div className="max-w-screen-xl bg-white mx-auto pb-12 pt-12 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:flex-col sm:align-center">
        <div className="py-12 flex justify-center">
          <NavLink
            to="/pricing"
            className="text-gray-500 text-sm justify-center hover:text-gray-600 transition duration-300 ease-in-out"
          >
            <div className="flex flex-row items-center">
              <span>← Back to pricing</span>
              <img className="ml-3 cursor-pointer h-6 w-auto sm:h-8" src={Logo} alt="JustDjango" />
            </div>
          </NavLink>
        </div>
        <h1 className="text-5xl leading-none font-extrabold text-gray-900 sm:text-center">
          Payment
        </h1>
        <p className="mt-5 text-xl leading-7 text-gray-600 sm:text-center">
          Choose a payment option
        </p>

        <div className="py-6 lg:py-12 space-y-12">
          <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:max-w-3xl">
            <li onClick={() => setPaymentOption("card")} className="cursor-pointer">
              <div className="space-y-6">
                <div className="space-y-2">
                  <div
                    className={`py-5 px-4 flex flex-col justify-center items-center text-lg leading-6 font-medium space-y-1 ${
                      paymentOption === "card"
                        ? "bg-gray-100 border border-gray-300"
                        : "bg-white border border-gray-200"
                    }`}
                  >
                    <div className="flex flex-row">
                      <VisaIcon className="h-10 w-10" />
                      <MastercardIcon className="h-10 w-10 ml-2" />
                      <AmericanExpressIcon className="h-10 w-10 ml-2" />
                    </div>
                    <div className="pt-3 mt-5">
                      <h3>Debit / Credit Card</h3>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li onClick={() => setPaymentOption("paypal")} className="cursor-pointer">
              <div className="space-y-6">
                <div className="space-y-2">
                  <div
                    className={`py-5 px-4 flex flex-col justify-center items-center text-lg leading-6 font-medium space-y-1 ${
                      paymentOption === "paypal"
                        ? "bg-gray-100 border border-gray-300"
                        : "bg-white border border-gray-200"
                    }`}
                  >
                    <div className="flex flex-row">
                      <PayPalIcon className="h-10" />
                    </div>
                    <div className="pt-3 mt-5">
                      <h3>PayPal</h3>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="py-6 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-12 xl:pr-12">
          <div
            className="max-w-lg mx-auto h-full flex flex-col justify-center"
            style={{ minWidth: "300px" }}
          >
            {!plan && (
              <div className="w-full mx-auto">
                <div className="animate-pulse flex space-x-4">
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-gray-300 rounded-sm w-1/3" />
                    <div className="h-12 bg-gray-300 rounded-sm w-1/4" />
                    <div className="space-y-2">
                      <div className="h-12 bg-gray-300 rounded-sm" />
                      <div className="h-12 bg-gray-300 rounded-sm" />
                      <div className="h-12 bg-gray-300 rounded-sm" />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {plan && (
              <>
                <p className="mt-10 leading-6 text-gray-500">Enroll for {plan.name}</p>
                <div className="mt-2 flex flex-row items-center">
                  <div>
                    <h2 className="text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-9">
                      ${plan.amount}
                    </h2>
                  </div>
                  {plan.period === "Monthly" && (
                    <div className="ml-2 text-xs leading-4">
                      per
                      <br />
                      month
                    </div>
                  )}
                </div>
                <div className="mt-8 text-base leading-6 text-gray-500">
                  <div className="flex flex-row justify-between">
                    <div>
                      <p className="text-lg text-gray-800 font-semibold">Total Due</p>
                      <p className="text-sm">
                        Billed {plan.period === "Monthly" ? "monthly" : "one time"}
                      </p>
                    </div>
                    <div>
                      <p className="text-lg text-gray-800 font-semibold">${planAmountDisplay()}</p>
                    </div>
                  </div>
                </div>
                {paymentOption === "card" ? (
                  <>
                    <Button
                      loading={loading}
                      disabled={loading}
                      type="button"
                      onClick={handleStripeCheckout}
                      className={`${
                        loading ? "cursor-not-allowed" : ""
                      } mt-8 bg-red-600 border border-transparent rounded-md py-3 text-lg leading-5 font-semibold text-white hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-300 ease-in-out hover:bg-red-600 transform hover:-translate-y-1`}
                      label={loading ? "Creating a checkout session..." : "Pay with card"}
                    />
                    <p className="mt-3 text-center text-gray-700 text-sm">
                      Payments handled by Stripe
                    </p>
                  </>
                ) : (
                  <div className="mt-3">
                    <PaypalPurchaseButton planID={planID} callback={handlePaypalCheckout} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
