import Egg from "../assets/images/badges/egg.svg";
import Horn from "../assets/images/badges/horn.svg";
import Rainbow from "../assets/images/badges/rainbow.svg";
import Shoe from "../assets/images/badges/shoe.svg";
import Wings from "../assets/images/badges/wings.svg";
import { BadgeName } from "../models";

export const BADGENAMES = {
  EGG: "egg",
  SHOE: "shoe",
  HORN: "horn",
  WINGS: "wings",
  RAINBOW: "rainbow"
};

export const BADGES = {
  [BADGENAMES.EGG]: Egg,
  [BADGENAMES.SHOE]: Shoe,
  [BADGENAMES.HORN]: Horn,
  [BADGENAMES.WINGS]: Wings,
  [BADGENAMES.RAINBOW]: Rainbow
};

export const BADGE_EXPERIENCE = {
  [BADGENAMES.EGG]: 100,
  [BADGENAMES.SHOE]: 500,
  [BADGENAMES.WINGS]: 2000,
  [BADGENAMES.HORN]: 8000,
  [BADGENAMES.RAINBOW]: 17500
};

export const getNextBadge = (badge: BadgeName): string => {
  if (badge === BADGENAMES.EGG) return BADGENAMES.SHOE;
  if (badge === BADGENAMES.SHOE) return BADGENAMES.HORN;
  if (badge === BADGENAMES.HORN) return BADGENAMES.WINGS;
  if (badge === BADGENAMES.WINGS) return BADGENAMES.RAINBOW;
  return "";
  // TODO: unicorn
};

export const BADGE_DETAILS = {
  egg: {
    title: "The Egg",
    description:
      "This is the start of your journey. You've got no experience under your belt, but a ton of opportunity to gain it! ",
    exp_required: 0
  },
  shoe: {
    title: "The Shoe",
    description:
      "You've gotten your hands dirty a little! Now that you're on your way, you can at least say you understand the basic concepts and how Django is pieced together." +
      " There's still a long way to go, but be encouraged that you've made it this far!",
    exp_required: 500
  },
  horn: {
    title: "The Horn",
    description:
      "By now you should be getting into the swing of it. You should have a grasp of the core layers of Django (models, views, forms, templates, urls). By now you actually know enough to build blogs, notepads" +
      " and other basic systems.",
    exp_required: 3000
  },
  wings: {
    title: "The Wings",
    description:
      "You should be a lot more comfortable working with the framework at this point. Starting a new project shouldn't scare you, and hopefully you've also seen that most projects are a repitition of GET,POST,PUT and DELETE" +
      " endpoints. The business logic is only what changes.",
    exp_required: 8000
  },
  rainbow: {
    title: "The Rainbow",
    description:
      "Django should feel like a powerful addition to your developer toolkit. It should be easy to design systems in terms of Django's models. Deploying Django should also feel somewhat comfortable. You should now feel ready to take on" +
      "freelancing or applying for a job as a Python/Django developer.",
    exp_required: 17500
  }
};
