import axios from "axios";
import api from "src/api";
import { UserData } from "src/contexts";

class AuthService {
  setUserInLocalStorage(data: UserData) {
    localStorage.setItem("user", JSON.stringify(data));
  }

  login(email: string, password: string): Promise<UserData> {
    return axios.post(api.auth.login, { email, password }).then((response) => {
      if (response.data.access_token) {
        this.setUserInLocalStorage(response.data);
      }
      return response.data;
    });
  }

  googleLogin(access_token: string, id_token: string): Promise<UserData> {
    return axios.post(api.auth.social.google.login, { access_token, id_token }).then((response) => {
      if (response.data.access_token) {
        this.setUserInLocalStorage(response.data);
      }
      return response.data;
    });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(email: string, password1: string, password2: string) {
    return axios.post(api.auth.signup, { email, password1, password2 });
  }

  getCurrentUser() {
    const user = localStorage.getItem("user")!;
    return JSON.parse(user);
  }
}

export default new AuthService();
