import React, { createContext, ReactNode, useState } from "react";
import axios, { AxiosInstance } from "axios";
import { history } from "src/helpers";
import api from "src/api";
import AuthService from "src/services/auth.service";
import authHeader from "src/services/auth-header";

const DefaultProps = {
  login: () => null,
  googleLogin: () => null,
  logout: () => null,
  register: () => null,
  authAxios: axios,
  user: null
};

export interface UserData {
  access_token: string;
  refresh_token: string;
  user: {
    email: string;
    is_active_bootcamp_user: false;
    is_manager: false;
    pk: 2;
  };
}

export interface AuthProps {
  login: (email: string, password: string) => any;
  googleLogin: (access_token: string, id_token: string) => any;
  logout: () => void;
  register: (email: string, password1: string, password2: string) => any;
  authAxios: AxiosInstance;
  user: UserData | null;
}

export const AuthContext = createContext<AuthProps>(DefaultProps);

export const AuthContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState(() => AuthService.getCurrentUser());

  function register(email: string, password1: string, password2: string) {
    return AuthService.register(email, password1, password2);
  }

  function googleLogin(access_token: string, id_token: string) {
    return AuthService.googleLogin(access_token, id_token).then((data) => {
      setUser(data);
      return data;
    });
  }

  function login(email: string, password: string) {
    return AuthService.login(email, password).then((data) => {
      setUser(data);
      return data;
    });
  }

  function logout() {
    AuthService.logout();
    setUser(null);
    history.push("/login");
  }

  // axios instance for making requests
  const authAxios = axios.create();

  // request interceptor for adding token
  authAxios.interceptors.request.use((config) => {
    // add token to request headers
    config.headers = authHeader();
    return config;
  });

  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && originalRequest.url === api.auth.refreshToken) {
        logout();
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return axios
          .post(api.auth.refreshToken, {
            refresh: user.refresh_token
          })
          .then((res) => {
            const access_token = res.data.access;

            // 1) put token to LocalStorage
            setUser({
              ...user,
              access_token
            });
            AuthService.setUserInLocalStorage({
              ...user,
              access_token
            });
            // refreshAxios(access_token)

            // 2) Update axios headers with the new access token
            const newRequest = {
              ...originalRequest,
              headers: {
                ...originalRequest.headers,
                Authorization: `Bearer ${access_token}`
              }
            };

            authAxios.defaults.headers.Authorization = `Bearer ${access_token}`;

            // 3) return originalRequest object with Axios.
            return authAxios(newRequest);
          })
          .catch(() => {
            console.log("error refreshing token");
            // toast("Your session has expired. Please login again.", {
            //   duration: 6000,
            //   iconTheme: {
            //     primary: "#000",
            //     secondary: "#fff"
            //   }
            // });
            logout();
          });
      }

      // return Error object with Promise
      return Promise.reject(error);
    }
  );

  return (
    <AuthContext.Provider value={{ user, login, googleLogin, logout, register, authAxios }}>
      {children}
    </AuthContext.Provider>
  );
};
