import React from "react";
import { WithTransition } from "src/helpers";
import { CircleCrossIcon, CircleTickIcon, CrossIcon, InfoIcon, WarningIcon } from "./icons";

const MESSAGE_TYPES = {
  ERROR: "ERROR",
  WARNING: "WARNING",
  INFO: "INFO",
  SUCCESS: "SUCCESS"
};

const MESSAGE_THEME = {
  [MESSAGE_TYPES.ERROR]: {
    bodyBackground: "bg-red-300",
    bodyText: "text-white",
    headerText: "text-white",
    iconColour: "text-white"
  },
  [MESSAGE_TYPES.WARNING]: {
    bodyBackground: "bg-yellow-100",
    bodyText: "text-yellow-700",
    headerText: "text-yellow-800",
    iconColour: "text-yellow-400"
  },
  [MESSAGE_TYPES.INFO]: {
    bodyBackground: "bg-blue-100",
    bodyText: "text-blue-700",
    headerText: "text-blue-800",
    iconColour: "text-blue-400"
  },
  [MESSAGE_TYPES.SUCCESS]: {
    bodyBackground: "bg-green-100",
    bodyText: "text-green-700",
    headerText: "text-green-800",
    iconColour: "text-green-400"
  }
};

interface MessageProps {
  body: string | null; // TODO: convert to string -> fix the errorhandler function
  type: "ERROR" | "WARNING" | "INFO" | "SUCCESS";
  onDismiss?: () => void | null;
  noDismiss?: boolean;
}

export function Message({ body, type, onDismiss, noDismiss = false }: MessageProps): JSX.Element {
  const theme = MESSAGE_THEME[type];
  return (
    <WithTransition show={true}>
      <div className={`rounded-md ${theme.bodyBackground} p-4 mb-3`}>
        <div className="flex">
          <div className="flex-shrink-0">
            {type === "ERROR" && <CircleCrossIcon className={`h-5 w-5 ${theme.iconColour}`} />}
            {type === "SUCCESS" && <CircleTickIcon className={`h-5 w-5 ${theme.iconColour}`} />}
            {type === "INFO" && <InfoIcon className={`h-5 w-5 ${theme.iconColour}`} />}
            {type === "WARNING" && <WarningIcon className={`h-5 w-5 ${theme.iconColour}`} />}
          </div>
          <div className="ml-3">
            <p className={`text-sm font-medium ${theme.bodyText}`}>{body}</p>
          </div>
          {!noDismiss && onDismiss && (
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  onClick={() => onDismiss()}
                  className={`inline-flex ${theme.bodyBackground} rounded-md p-1.5 ${theme.bodyText}`}
                >
                  <span className="sr-only">Dismiss</span>
                  <CrossIcon className={`h-5 w-5 ${theme.iconColour}`} />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </WithTransition>
  );
}

Message.defaultProps = {
  onDismiss: () => null,
  noDismiss: false
};
