import React, { ReactNode } from "react";
import { SpinnerIcon } from "./icons";

interface ButtonProps {
  disabled?: boolean;
  type: "button" | "submit";
  className: string;
  loading?: boolean;
  label?: string;
  onClick?: () => void;
  children?: ReactNode;
}

export function Button({
  disabled = false,
  type,
  className,
  loading = false,
  label,
  onClick,
  children
}: ButtonProps): JSX.Element {
  return (
    <button type={type} disabled={disabled} className={className} onClick={onClick}>
      {loading ? (
        <span className="flex flex-row items-center justify-center">
          {label}
          <SpinnerIcon className="animate-spin ml-3 mr-3 h-5 w-5" />
        </span>
      ) : (
        label
      )}
      {children}
    </button>
  );
}

Button.defaultProps = {
  disabled: false,
  loading: false,
  label: "",
  onClick: () => null,
  children: null
};
