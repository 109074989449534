import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { Layout } from "src/containers";
import { history } from "src/helpers";
import { PrivateRoute } from "src/components";
import Logo from "src/assets/images/logo.svg";
import Dashboard from "./pages/dashboard";
import Landing from "./pages/landing";
import Contact from "./pages/contact";
import PrivacyPolicy from "./pages/privacy_policy";
import TermsAndConditions from "./pages/terms_and_conditions";
import NotFound from "./pages/not_found";
import Enroll from "./pages/enroll";
import Pricing from "./pages/pricing";
import Roadmap from "./pages/roadmap";
import FAQ from "./pages/faq";
import Classroom from "./pages/lecture/classroom";
import Login from "./pages/auth/login";
import Signup from "./pages/auth/signup";
import ForgotPassword from "./pages/auth/forgot_password";
import ConfirmPasswordReset from "./pages/auth/confirm_password_reset";
import ConfirmEmail from "./pages/auth/account_confirm_email";
import AccountProfile from "./pages/account/account_profile";
import AccountSubscription from "./pages/account/account_subscription";
import AccountTransactions from "./pages/account/account_transactions";
import Success from "./pages/success";
import Archives from "./pages/archives";
import Payment from "./pages/payment";

import { AppProviders } from "./providers";

const routes = [
  { path: "/", Component: Landing, name: "Landing" },
  { path: "/success", Component: Success, name: "Success" },
  { path: "/dashboard", Component: Dashboard, isPrivate: true, name: "Dashboard" },
  { path: "/roadmaps/:roadmapSlug", Component: Roadmap, name: "RoadmapDetail" },
  { path: "/archives/:roadmapSlug", Component: Archives, name: "RoadmapArchives" },
  { path: "/payment/:planID", Component: Payment, name: "Payment" }
];

// function App(): JSX.Element {
//   return (
//     <Router history={history}>
//       <AppProviders>
//         <Layout>
//           <Switch>
//             {/* Dashboard */}
//             {routes.map(({ isPrivate, name, path, Component }) => {
//               if (isPrivate) {
//                 return (
//                   <PrivateRoute exact path={path} key={name}>
//                     {({ match }) => (
//                       <CSSTransition
//                         in={match != null}
//                         timeout={1200}
//                         classNames="page"
//                         unmountOnExit
//                       >
//                         <div className="page">
//                           <Component />
//                         </div>
//                       </CSSTransition>
//                     )}
//                   </PrivateRoute>
//                 );
//               }
//               return (
//                 <Route exact path={path} key={name}>
//                   {({ match }) => (
//                     <CSSTransition
//                       in={match != null}
//                       timeout={1200}
//                       classNames="page"
//                       unmountOnExit
//                     >
//                       <div className="page">
//                         <Component />
//                       </div>
//                     </CSSTransition>
//                   )}
//                 </Route>
//               );
//             })}

//             {/* Landing */}

//             <Route path="/contact" component={Contact} />
//             <Route path="/enroll/:planID" component={Enroll} />

//             <Route path="/pricing" component={Pricing} />
//             <Route path="/faq" component={FAQ} />

//             <Route path="/privacy-policy" component={PrivacyPolicy} />
//             <Route path="/terms-and-conditions" component={TermsAndConditions} />

//             {/* Content */}
//             <PrivateRoute exact path="/courses/:courseSlug/:lectureSlug" component={Classroom} />

//             {/* Auth */}
//             <Route path="/login" component={Login} />
//             <Route path="/signup" component={Signup} />
//             <Route path="/forgot-password" component={ForgotPassword} />
//             <Route path="/password/reset/confirm/:uid/:token" component={ConfirmPasswordReset} />
//             <Route path="/accounts/confirm-email/:verifyKey" component={ConfirmEmail} />

//             {/* Account */}
//             <PrivateRoute path="/account/profile" component={AccountProfile} />
//             <PrivateRoute path="/account/subscription" component={AccountSubscription} />
//             <PrivateRoute path="/account/transactions" component={AccountTransactions} />

//             <Route component={NotFound} />
//           </Switch>
//         </Layout>
//       </AppProviders>
//     </Router>
//   );
// }

function App() {
  return (
    <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12">
      <div className="absolute inset-0 bg-[url(/img/grid.svg)] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"></div>
      <div className="relative bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
        <div className="mx-auto max-w-md">
          <img src={Logo} className="h-6" alt="JustDjango" />
          <div className="divide-y divide-gray-300/50">
            <div className="pt-8 text-base leading-7">
              <p className="text-2xl text-gray-900">JustDjango Learn has moved!</p>
              <p className="mt-3">
                Hey everyone. JustDjango courses are now available on{" "}
                <a className="text-blue-500" href="https://justdjango.com">
                  justdjango.com
                </a>
                . Soon this site will redirect you there automatically.
              </p>
              <p className="mt-3">
                You still have access to your membership and can login using your same account as
                always. Hope to see you there!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
