import { Merchant } from "../models";
import errorHandler from "./errorHandler";

function showUpgradeButton(merchant: Merchant): boolean {
  if (merchant) {
    if (merchant.planID === "django-free-trial") {
      return true;
    }
    if (merchant.status === "canceled") {
      return true;
    }
  }
  return false;
}

export { showUpgradeButton };
export * from "./ordinalSuffix";
export * from "./parsers";
export * from "./plans";
export * from "./profilePic";
export default errorHandler;
